import React from "react"
import styled from "styled-components"
import rfs from "../../utils/rfs"
import Eclipse from "../../images/ecllipse.svg"
import { Col, Row } from "../UI/Grid"
import ScaledImage from "../UI/ScaledImage"
import Element from "../UI/Element"
import { Anchor, Heading } from "../UI/Typography"
import { media } from "styled-bootstrap-grid"

const Office = ({ title, address, telno, faxno, embed, email }) => {
  return (
    <OfficeStyled>
      <Row>
        <Col col={{ md: 6 }} mb={{ _: 6, md: 0 }}>
          <ScaledImage scaleWidth={508} scaleHeight={296}>
            <iframe
              src={embed}
              allowfullscreen=""
              loading="lazy"
              title={title}
              style={{ border: 0 }}
            />
          </ScaledImage>
        </Col>
        <Col col={{ md: 6 }}>
          <Heading color="white" fs="h2" mb={6}>
            {title}
          </Heading>
          {address && (
            <Row>
              <Col col="auto">
                <Element minWidth={80}>Address</Element>
              </Col>
              <Col col={{ sm: "fill" }}>
                <Element fontWeight="bold">{address}</Element>
              </Col>
            </Row>
          )}
          {telno && (
            <Row mt={4}>
              <Col col="auto">
                <Element minWidth={80}>Tel. No.</Element>
              </Col>
              <Col col={{ sm: "fill" }}>
                {telno.map((t, idx) => (
                  <Element fontWeight="bold" key={idx}>
                    {t}
                  </Element>
                ))}
              </Col>
            </Row>
          )}
          {faxno && (
            <Row mt={4}>
              <Col col="auto">
                <Element minWidth={80}>Fax No.</Element>
              </Col>
              <Col col={{ sm: "fill" }}>
                <Element fontWeight="bold">{faxno}</Element>
              </Col>
            </Row>
          )}
          {email && (
            <Row mt={4}>
              <Col col="auto">
                <Element minWidth={80}>Email</Element>
              </Col>
              <Col col={{ sm: "fill" }}>
                <Anchor
                  fontWeight="bold"
                  as="a"
                  variant="white"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`mailto:${email}`}
                >
                  {email}
                </Anchor>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </OfficeStyled>
  )
}

const OfficeStyled = styled.div`
  background-color: var(--brand);
  color: #fff;
  padding: 24px;
  background-image: url(${Eclipse});
  background-repeat: no-repeat;
  background-position: right -150px bottom -150px;

  & + & {
    ${rfs("24px", "margin-top")};
  }

  ${media.lg`

  ${rfs("40px 60px", "padding")};
  `}
`

export default Office
