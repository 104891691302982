import styled, { css } from "styled-components"
import theme from "../../utils/theme"

export const FieldContainer = styled.div`
  position: relative;

  ${(p) =>
    p.prependIcon &&
    css`
      input + div {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        color: ${theme.colors.darkGray};
        font-size: 24px;
      }

      ${Field} {
        padding-left: 48px;
      }
    `}
`

export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;

  ${(p) =>
    p.required &&
    css`
      &:after {
        content: "*";
        color: red;
      }
    `}
`

export const Field = styled.input`
  height: 48px;
  width: 100%;
  display: block;
  border: 0;
  border: 1px solid #757575;
  background: #fff;
  transition: ${theme.transition};
  padding: 6px 12px;

  select& {
    cursor: pointer;
  }

  textarea& {
    resize: none;
    height: 100px;
  }

  &:focus {
    border-bottom-color: ${theme.colors.brand};
    outline: none;
  }

  /* Placeholder styles */
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.colors.darkGray};
  }
  :-ms-input-placeholder {
    color: ${theme.colors.darkGray};
  }

  ${(p) =>
    p.prepend === "true" &&
    css`
      & {
        padding-left: 40px;
      }
    `}
`

export const FieldGroup = styled.div`
  position: relative;

  ${(p) =>
    p.errors &&
    css`
      ${Field} {
        border-color: ${theme.colors.danger};
      }
    `}
`

export const FieldError = styled.div`
  color: red;
  font-size: 12px;
`

export const FieldCheckbox = styled.div`
  position: relative;

  label {
    user-select: none;
    position: relative;
    display: inline-block;
    padding-left: 32px;
    cursor: pointer;
    margin-bottom: 0;
  }

  ${(p) =>
    p.inline &&
    css`
      label {
        padding-left: 0;
        min-height: 22px;
        min-width: 18px;
      }
    `}

  input {
    opacity: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    cursor: pointer;

    &:checked {
      & ~ .square {
        color: white;
        background: ${theme.colors.brand};
      }
    }
  }

  .square {
    position: absolute;
    top: 4px;
    left: 0;
    height: 18px;
    width: 18px;
    background: ${theme.colors.lilac};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ${theme.transition};
    color: transparent;
  }
`
