import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import ContactForm from "../components/Contact/ContactForm"
import Offices from "../components/Contact/Offices"
import Seo from "../components/Layout/Seo"
import Site from "../components/Layout/Site"
import HeavyLifting from "../components/Shared/HeavyLifting"
import ScaledElement from "../components/UI/ScaledElement"
import ScaledImage from "../components/UI/ScaledImage"

const Contact = () => {
  return (
    <Site>
      <Seo title="Contact" />
      <ScaledElement scaleWidth={1440} scaleHeight={240} fullWidth>
        <ScaledImage bg={true}>
          <StaticImage
            src="../images/banner-contact.jpg"
            alt="banner"
            layout="fullWidth"
          />
        </ScaledImage>
      </ScaledElement>
      <ContactForm />
      <Offices />
      <HeavyLifting />
    </Site>
  )
}

export default Contact
