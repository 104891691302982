import React from "react"
import { Container } from "../UI/Grid"
import { Heading } from "../UI/Typography"
import Office from "./Office"

const Offices = () => {
  const offices = [
    {
      title: "Main Office",
      address:
        "5th Floor, AIC Center, 204 Escolta, Binondo, Manila, Philippines",
      telno: ["(+632) 242-1255", "(+632) 242-5485", "(+632) 242-1368"],
      faxno: "(+632) 242-1396",
      email: "sales@boeingmhc.com.ph",
      embed:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3861.0537313237737!2d120.97547799919738!3d14.596014148936723!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7c530d2e8e33d09a!2sBoeing%20Material%20Handling%20Corporation.!5e0!3m2!1sen!2sph!4v1647496907622!5m2!1sen!2sph",
    },
    {
      title: "Bicutan Office",
      address:
        "Gate 4 Emran Cmpd. 8014 Km 15 West Service Rd., Sun Valley, Parañaque City",
      telno: ["(+632) 824-1719"],
      faxno: "(+632) 824-1719",
      email: "",
      embed:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3861.0537313237737!2d120.97547799919738!3d14.596014148936723!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7c530d2e8e33d09a!2sBoeing%20Material%20Handling%20Corporation.!5e0!3m2!1sen!2sph!4v1647496907622!5m2!1sen!2sph",
    },
    {
      title: "Cebu Office",
      address: "B2 Santiago Bldg. Patalinghug Ave., Pajo Lapulapu City, Cebu",
      telno: ["(+6332) 341-1120"],
      faxno: "(+6332) 341-1120",
      email: "",
      embed:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3861.0537313237737!2d120.97547799919738!3d14.596014148936723!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7c530d2e8e33d09a!2sBoeing%20Material%20Handling%20Corporation.!5e0!3m2!1sen!2sph!4v1647496907622!5m2!1sen!2sph",
    },
    {
      title: "Davao Office",
      address: "Door 4, Tropicana Bldg. Km 7 Lanang, Davao City",
      telno: ["(+6382) 234-9927"],
      faxno: "(+6382) 234-9927",
      email: "",
      embed:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3861.0537313237737!2d120.97547799919738!3d14.596014148936723!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7c530d2e8e33d09a!2sBoeing%20Material%20Handling%20Corporation.!5e0!3m2!1sen!2sph!4v1647496907622!5m2!1sen!2sph",
    },
  ]

  return (
    <Container pb={20}>
      <Heading textAlign="center" fs="h2" color="text" mb={10}>
        Our Offices
      </Heading>
      {offices.map((office, index) => {
        return (
          <Office
            title={office.title}
            address={office.address}
            telno={office.telno}
            faxno={office.faxno}
            embed={office.embed}
            email={office.email}
          />
          // <div
          //   style={{
          //     backgroundSize: "cover",
          //     padding: "2vw 2vw",
          //     maxWidth: "1105px",
          //     display: "grid",
          //     gridTemplateColumns: "auto auto",
          //     marginBottom: "20px",
          //   }}
          //   className="kg-width-full"
          //   key={`key-${index}`}
          // >
          //   <iframe
          //     src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3861.0537313237737!2d120.97547799919738!3d14.596014148936723!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7c530d2e8e33d09a!2sBoeing%20Material%20Handling%20Corporation.!5e0!3m2!1sen!2sph!4v1647496907622!5m2!1sen!2sph"
          //     width="400"
          //     height="350"
          //     style={{ border: 0 }}
          //     allowfullscreen=""
          //     loading="lazy"
          //     title={office.title}
          //   />
          //   <section style={{ color: "#fff", padding: "0 3vw" }}>
          //     <h3 className="mt-0" style={{ color: "#fff", textAlign: "left" }}>
          //       {office.title}
          //     </h3>
          //     <div
          //       style={{
          //         display: "grid",
          //         gridTemplateColumns: "140px auto",
          //         width: "100%",
          //         marginTop: "25px",
          //       }}
          //     >
          //       <section style={{ marginRight: "50px" }}>
          //         <span>Address</span>
          //       </section>
          //       <section>
          //         <span>{office.address}</span>
          //       </section>
          //     </div>
          //     <div
          //       style={{
          //         display: "grid",
          //         gridTemplateColumns: "110px auto",
          //         width: "100%",
          //       }}
          //     >
          //       <section style={{ marginRight: "50px" }}>
          //         <span>Tel No.</span>
          //       </section>
          //       <section>
          //         <span>
          //           {office.telno.map((item) => {
          //             return (
          //               <ul style={{ listStyleType: "none", margin: "0" }}>
          //                 <li
          //                   style={{
          //                     listStyleType: "none",
          //                     margin: "0",
          //                   }}
          //                 >
          //                   {item}
          //                 </li>
          //               </ul>
          //             )
          //           })}
          //         </span>
          //       </section>
          //     </div>
          //     <div
          //       style={{
          //         display: "grid",
          //         gridTemplateColumns: "140px auto",
          //         width: "100%",
          //         marginTop: "25px",
          //       }}
          //     >
          //       <section style={{ marginRight: "50px" }}>
          //         <span>Fax No.</span>
          //       </section>
          //       <section>
          //         <span>{office.faxno}</span>
          //       </section>
          //     </div>
          //     {office.email && (
          //       <div
          //         style={{
          //           display: "grid",
          //           gridTemplateColumns: "140px auto",
          //           width: "100%",
          //           marginTop: "25px",
          //         }}
          //       >
          //         <section style={{ marginRight: "50px" }}>
          //           <span>Email</span>
          //         </section>
          //         <section>
          //           <span>{office.email}</span>
          //         </section>
          //       </div>
          //     )}
          //   </section>
          // </div>
        )
      })}
    </Container>
  )
}

export default Offices
