import React, { useState } from "react"
import Element from "../UI/Element"
import { Col, Container, Row } from "../UI/Grid"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Field,
  FieldContainer,
  FieldError,
  FieldGroup,
  Label,
} from "../UI/Forms"
import * as yup from "yup"
import Button from "../UI/Button"
import { Anchor, Heading, Paragraph } from "../UI/Typography"
import { Link, navigate } from "gatsby"
import { Section } from "../UI/Section"

const schema = yup.object().shape({
  name: yup.string().required("This is required."),
  email: yup
    .string()
    .required("This is required.")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,13}$/i,
      "Please enter a valid email."
    ),
  message: yup.string().required("This is required."),
})

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const isBrowser = typeof window !== "undefined"

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    console.log(data)
    try {
      const response = await fetch(
        `${
          isBrowser &&
          window?.location?.origin === "https://boeingmhc.digiteer.dev"
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/messages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
      const json = await response.json()
      console.log(json.status)
      if (json.status === "SUCCESS") {
        navigate("/thank-you")
      }
    } catch (err) {
      setError(err.message)
    }
    setIsSubmitting(false)
  }

  return (
    <Section>
      <Container>
        <Row justifyContent="center" textAlign="center" mb={15}>
          <Col col={{ lg: 5 }}>
            <Heading as="h1" fs="h1" mb={4}>
              Contact
            </Heading>
            <Paragraph>
              For any questions or concerns, or to get a quote of our products
              and services, you may reach out to us through this contact form.
            </Paragraph>
          </Col>
        </Row>
        <Row justifyContent="center">
          <Col col={{ lg: 4 }}>
            <Element
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              rowGap="6"
              display="grid"
            >
              {error && (
                <Element bg="red" color="#fff" p={3}>
                  {error}
                </Element>
              )}
              <FieldGroup errors={errors.name}>
                <Label htmlFor="name" required>
                  Name
                </Label>
                <FieldContainer>
                  <Field
                    {...register("name")}
                    placeholder="e.g. Juan Dela Cruz"
                  />
                </FieldContainer>
                {errors.name && <FieldError>{errors.name.message}</FieldError>}
              </FieldGroup>

              <FieldGroup errors={errors.email}>
                <Label htmlFor="email" required>
                  Email Address
                </Label>
                <FieldContainer>
                  <Field
                    {...register("email")}
                    placeholder="e.g. jdl@website.com"
                  />
                </FieldContainer>
                {errors.email && (
                  <FieldError>{errors.email.message}</FieldError>
                )}
              </FieldGroup>

              <FieldGroup errors={errors.message}>
                <Label htmlFor="message" required>
                  Message
                </Label>
                <FieldContainer>
                  <Field
                    as="textarea"
                    {...register("message")}
                    placeholder="To be able to serve you better, please include information that may be relevant to your inquiry here."
                  />
                </FieldContainer>
                {errors.message && (
                  <FieldError>{errors.message.message}</FieldError>
                )}
              </FieldGroup>

              <Row justifyContent="center" textAlign="center">
                <Col col={{ lg: 10 }}>
                  <Paragraph fs="caption" mb={8}>
                    By clicking ‘Submit,’ you are agreeing to our{" "}
                    <Anchor as={Link} to="/terms-of-use">
                      Terms of Use
                    </Anchor>{" "}
                    and{" "}
                    <Anchor as={Link} to="/privacy-policy">
                      Privacy Policy
                    </Anchor>
                    .
                  </Paragraph>
                  <Button
                    type="submit"
                    variant="brand"
                    disabled={isSubmitting}
                    block
                  >
                    {isSubmitting ? "Sending..." : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Element>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default ContactForm
